@use "../config/" as *;
@forward "../main-scss/";
@forward "../swiper/swiper-bundle";
@forward "../standing/standing-04";
@forward "../standing/standing-20";
@forward "../listing/series-listing";
@include mq(col-lg) {
    .row > *:nth-child(2) {
        padding-top: 5rem
    }
}